import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IContactUsData, IContactUsResponse } from '../interfaces/contact-us';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/enviroment';
import { StatusCounts } from '../../../layouts/components/side-menu/assets/live-point';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  private _HttpClient = inject(HttpClient);
  public statusCounts = new BehaviorSubject<StatusCounts>({} as StatusCounts);

  getData( page: number, limit: number, name?: string | null ): Observable<IContactUsResponse[]> {
    let optionalParams = [];
    if (name) optionalParams.push(`&name=${name}`);
    return this._HttpClient.get<IContactUsResponse[]>( `${ environment.apiUrl }/v1/globals/contact-us?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}`
    );
  }
  getDetails(id: string) {
    return this._HttpClient.get<IContactUsData>(`${environment.apiUrl}/v1/globals/contact-us/${id}`);
  }

  getStatusCounts() :Observable<StatusCounts> {
    return this._HttpClient.get<StatusCounts>(`${ environment.apiUrl }/v1/globals/contact-removed-counts`)
  }
}
